import { getReviewsForGallery } from '@api/reviews';
import { mediaApiUrl } from '@settings';
import { atom } from 'jotai';
import { Gallery, Review } from 'types/reviews';
import { Decorated } from 'types/vendor';
import { Media, ReviewMedia } from '../../../types/vendor/properties';
import { reduxAtom } from '../../jotai/redux-atoms';
import { getFilterByMediaType } from './helpers';

export type EntryPoint =
	| 'Mosaic'
	| 'StorefrontGallery'
	| 'Quicklinks'
	| 'Reviews';
type CurrentView = 'Overview' | 'Lightbox';
export type VendorMediaKey = 'all' | 'photos' | 'videos' | 'tours' | 'reviews';
export type VendorMedia = {
	[K in VendorMediaKey]: Media[];
};

type State = {
	entryPoint: EntryPoint;
	currentView: CurrentView;
	index: number;
	filter: VendorMediaKey;
	vendorName: string;
	vendorMedia: VendorMedia;
	isPlaying: boolean;
	playingId: string;
	currentReview: ReviewMedia | undefined;
	isOpen: boolean;
};

export const initialState: State = {
	entryPoint: 'StorefrontGallery',
	currentView: 'Overview',
	index: 0,
	filter: 'all',
	vendorName: '',
	vendorMedia: {
		all: [],
		photos: [],
		videos: [],
		tours: [],
		reviews: [],
	},
	isPlaying: false,
	playingId: '',
	currentReview: undefined,
	isOpen: false,
};

// Entry point
export const entryPointAtom = atom<State['entryPoint']>(
	initialState.entryPoint,
);

// Current view
export const currentViewAtom = atom<State['currentView']>(
	initialState.currentView,
);

export const currentViewLightboxAtom = atom<boolean>((get) => {
	return get(currentViewAtom) === 'Lightbox';
});

export const isUnifiedLightboxOpenAtom = atom<State['isOpen']>(
	initialState.isOpen,
);

// Current index
export const currentIndexAtom = atom<State['index']>(0);

// Current filter
export const filterValues: State['filter'][] = [
	'all',
	'photos',
	'videos',
	'tours',
	'reviews',
];
export const displayedFilterValuesAtom = atom<VendorMediaKey[]>((get) => {
	return get(vendorHasOnlyPhotosAtom) ? [filterValues[0]] : filterValues;
});
export const filterLabels: Record<State['filter'], string> = {
	all: 'Portfolio',
	photos: 'Photos',
	videos: 'Videos',
	tours: 'Virtual tours',
	reviews: 'Reviews',
};
export const currentFilterAtom = atom<State['filter']>(filterValues[0]);

// Vendor Info (Name + Media)
export const vendorNameAtom = atom<State['vendorName']>('');
export const vendorMediaAtom = atom<State['vendorMedia']>({
	all: [],
	photos: [],
	videos: [],
	tours: [],
	reviews: [],
});
export const computedMediaAtom = atom((get) => {
	const currentFilter = get(currentFilterAtom);
	const media = get(vendorMediaAtom);
	return media[currentFilter];
});
export const setVendorInfoAtom = atom<null, [value: Decorated], void>(
	null,
	(_get, set, value) => {
		const vendorMedia: VendorMedia = (value.mediaSummary?.media || []).reduce(
			(acc, item) => {
				acc.all.push(item);
				if (item.mediaType === 'PHOTO') acc.photos.push(item);
				if (item.mediaType === 'VIDEO') acc.videos.push(item);
				if (item.mediaType === '360TOUR') acc.tours.push(item);
				return acc;
			},
			{
				all: [],
				photos: [],
				videos: [],
				tours: [],
				reviews: [],
			} as State['vendorMedia'],
		);
		set(vendorMediaAtom, vendorMedia);
		set(vendorNameAtom, value.name || '');
	},
);

// Total number of items
export const totalItemsAtom = atom<Record<State['filter'], number>>((get) => {
	const media = get(vendorMediaAtom);
	return {
		all: media.photos.length + media.videos.length + media.tours.length,
		photos: media.photos.length,
		videos: media.videos.length,
		tours: media.tours.length,
		reviews: media.reviews.length,
	};
});
export const currentFilterTotalItemsAtom = atom<number>((get) => {
	const currentFilter = get(currentFilterAtom);
	return get(totalItemsAtom)[currentFilter];
});
export const vendorHasOnlyPhotosAtom = atom<boolean>((get) => {
	const totalItems = get(totalItemsAtom);
	return (
		totalItems.photos > 0 &&
		totalItems.videos === 0 &&
		totalItems.tours === 0 &&
		totalItems.reviews === 0
	);
});
// Header title
export const headerTitleAtom = atom<string>((get) => {
	if (get(currentViewAtom) === 'Lightbox') {
		return filterLabels[get(currentFilterAtom)];
	}
	if (get(currentViewAtom) === 'Overview') {
		return get(vendorNameAtom);
	}
	return '';
});

// Navigation atoms
type OpenLightbox = {
	overview: boolean;
	indexInAllMedia: number;
	filter?: VendorMediaKey;
	reviewMediaId?: string;
};
export const openUnifiedLightboxAtom = atom<null, [value: OpenLightbox], void>(
	null,
	async (get, set, value) => {
		console.info('Opening UnifiedLightbox with:', value);

		// Retrieve vendor data from Redux store
		const { vendor } = get(reduxAtom);
		set(
			setVendorInfoAtom,
			(vendor?.vendor as Vendor.Decorated) || initialState.vendorMedia,
		);

		// Fetch reviews & reviews media from API
		await set(fetchReviewsAtom, {
			vendorId: vendor.vendor?.id,
			reviewMediaId: value.reviewMediaId,
		});

		// Set filter
		if (get(vendorHasOnlyPhotosAtom)) {
			// If only has photos, we default Portfolio
			set(currentFilterAtom, filterValues[0]);
		} else {
			if (value.filter) {
				set(currentFilterAtom, value.filter);
			} else {
				// when no param is passed, get from media type
				const currentMedia = get(vendorMediaAtom).all[value.indexInAllMedia];
				const mediaType = currentMedia?.mediaType;
				set(currentFilterAtom, getFilterByMediaType(mediaType));
			}
		}

		// Set current Index
		// after checking if passed Check if value.index is valid
		const totalItems = get(totalItemsAtom);
		if (
			value.indexInAllMedia < 0 &&
			(value.indexInAllMedia >= totalItems?.all ||
				value.indexInAllMedia >= totalItems?.reviews)
		) {
			console.warn('Invalid index', value.indexInAllMedia, totalItems?.all);
			return;
		}

		// If a filter value was passed, find index in the computedMedia
		if (value?.filter === 'reviews') {
			if (value.overview === false) {
				const reviewMediaIndex = get(vendorMediaAtom).reviews.findIndex(
					(media: Media) => media.id === value.reviewMediaId,
				);
				set(currentIndexAtom, reviewMediaIndex);
			} else {
				set(currentIndexAtom, value.indexInAllMedia || 0);
			}
		} else {
			if (value.indexInAllMedia === 0) {
				set(currentIndexAtom, 0);
			} else if (value?.filter !== 'all') {
				const mediaId = get(vendorMediaAtom).all[value.indexInAllMedia].id;
				let foundIndex = get(computedMediaAtom).findIndex(
					(media) => media.id === mediaId,
				);
				if (foundIndex === -1) {
					console.warn('UnifiedLightbox | No index found for media', mediaId);
					foundIndex = 0;
				}
				set(currentIndexAtom, foundIndex);
			} else {
				set(currentIndexAtom, value.indexInAllMedia || 0);
			}
		}

		// If only 1 entry, skip overview
		if (totalItems[get(currentFilterAtom)] === 1) {
			set(currentViewAtom, 'Lightbox');
		} else {
			set(currentViewAtom, value.overview ? 'Overview' : 'Lightbox');
		}

		set(isUnifiedLightboxOpenAtom, true);
	},
);

export const closeUnifiedLightboxAtom = atom<null, [], void>(
	null,
	(_get, set) => {
		set(isUnifiedLightboxOpenAtom, initialState.isOpen);
		set(entryPointAtom, initialState.entryPoint);
		set(currentViewAtom, initialState.currentView);
		set(currentIndexAtom, initialState.index);
		set(currentFilterAtom, initialState.filter);
		set(vendorMediaAtom, initialState.vendorMedia);
		set(vendorNameAtom, initialState.vendorName);
		set(isPlayingAtom, initialState.isPlaying);
		set(playingIdAtom, initialState.playingId);
		set(currentReviewAtom, initialState.currentReview);
	},
);

export const nextMediaAtom = atom<
	null,
	[callback: ((idx: number) => void) | undefined],
	void
>(null, (get, set, callback) => {
	const index = get(currentIndexAtom);
	const mediaItems = get(computedMediaAtom);

	if (index < mediaItems.length - 1) {
		if (get(currentFilterAtom) === 'reviews') {
			const reviewMediaId = mediaItems[index + 1].id;
			set(setCurrentReviewAtom, reviewMediaId);
		}

		set(lightboxNavigationChange, {
			value: index + 1,
			callback,
		});
	}
});

export const prevMediaAtom = atom<
	null,
	[callback: ((idx: number) => void) | undefined],
	void
>(null, (get, set, callback) => {
	const index = get(currentIndexAtom);

	if (index > 0) {
		if (get(currentFilterAtom) === 'reviews') {
			const mediaItems = get(computedMediaAtom);
			const reviewMediaId = mediaItems[index - 1].id;
			set(setCurrentReviewAtom, reviewMediaId);
		}

		set(lightboxNavigationChange, {
			value: index - 1,
			callback,
		});
	}
});

type NavigationChange = {
	value: number;
	callback?: (index: number) => void;
};

export const lightboxNavigationChange = atom<null, [NavigationChange], void>(
	null,
	(get, set, { value, callback }) => {
		const index = get(currentIndexAtom);
		if (index !== value) {
			set(currentIndexAtom, value);
			if (callback) callback(value);
			set(setIsPlayingAtom, '');
		}
	},
);

export const currentOrientationAtom = atom<
	'portrait' | 'landscape' | 'unknown'
>((get) => {
	const index = get(currentIndexAtom);
	const mediaItems = get(computedMediaAtom);
	const media: Media | null = mediaItems.length ? mediaItems[index] : null;

	if (!media || media.mediaType !== 'PHOTO') {
		return 'landscape';
	}

	const height = 'height' in media ? Number(media.height) : 0;
	const width = 'width' in media ? Number(media.width) : 0;

	if (height === 0 || width === 0) {
		return 'unknown';
	}
	return width >= height ? 'landscape' : 'portrait';
});

// Player atoms
export const isPlayingAtom = atom<State['isPlaying']>(initialState.isPlaying);
export const playingIdAtom = atom<State['playingId']>(initialState.playingId);
export const setIsPlayingAtom = atom<null, [value: string], void>(
	null,
	(_get, set, value) => {
		set(isPlayingAtom, value !== '');
		set(playingIdAtom, value);
	},
);

// Reviews atoms
export const setReviewsMediaAtom = atom<null, [value: Media[]], void>(
	null,
	(get, set, value) => {
		const currentVendorMedia = get(vendorMediaAtom);
		const newVendorMedia = {
			...currentVendorMedia,
			reviews: value,
		} as State['vendorMedia'];
		set(vendorMediaAtom, newVendorMedia);
	},
);
export const currentReviewAtom = atom<State['currentReview']>(
	initialState.currentReview,
);
export const setCurrentReviewAtom = atom<null, [value: string], void>(
	null,
	(get, set, value) => {
		const currentReview = get(vendorMediaAtom).reviews.find(
			(media: Media) => media.id === value,
		);
		set(currentReviewAtom, currentReview as ReviewMedia);
	},
);

type fetchReviews = {
	vendorId: string;
	reviewMediaId?: string;
};
export const fetchReviewsAtom = atom<null, [value: fetchReviews], void>(
	null,
	async (get, set, value) => {
		// Fetch initial load of reviews
		const result = await getReviewsForGallery(value.vendorId, 1).then((r) =>
			r.json(),
		);

		const reviews: Media[] = result.data.flatMap((review: Review) =>
			review?.gallery.map((media: Gallery) => ({
				...media,
				mediaType: 'PHOTO',
				width: media.width?.toString() || '0',
				height: media.height?.toString() || '0',
				url: mediaApiUrl + media.sourceId,
				reviewId: review.id,
				reviewRating: review.rating,
				reviewCreatedDate: review.createdDate,
				reviewAuthorFirstname: review.reviewer.firstName,
				reviewAuthorLastname: review.reviewer.lastName,
				reviewContent: review.content,
			})),
		);
		set(setReviewsMediaAtom, reviews);

		const maxReviewsPerFetch = 500; // From reviews API.
		const totalReviews = result.reviewsSummary.gallery.photoCount;
		const fetchNumber = totalReviews / maxReviewsPerFetch;

		for (let i = 1; i < fetchNumber; i++) {
			const result = await getReviewsForGallery(value.vendorId, i + 1).then(
				(r) => r.json(),
			);
			const reviews: Media[] = result.data.flatMap((review: Review) =>
				review.gallery.map((media: Gallery) => ({
					...media,
					mediaType: 'PHOTO',
					width: media.width?.toString() || '0',
					height: media.height?.toString() || '0',
					url: mediaApiUrl + media.sourceId,
					reviewId: review.id,
					reviewRating: review.rating,
					reviewCreatedDate: review.createdDate,
					reviewAuthorFirstname: review.reviewer.firstName,
					reviewAuthorLastname: review.reviewer.lastName,
					reviewContent: review.content,
				})),
			);
			set(setReviewsMediaAtom, get(vendorMediaAtom).reviews.concat(reviews));
		}

		// Set current review
		if (value.reviewMediaId) {
			set(setCurrentReviewAtom, value.reviewMediaId);
		}
	},
);
